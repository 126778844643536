/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { AppProvider } from "./src/components/context/AppContext";
import Canvas from './src/components/canvas/canvas'




export const wrapPageElement = ({ element, props }) => {

    const pageContext = props?.page?.pageContext

    return (
        <AppProvider><Canvas><div {...props}>{element}</div></Canvas></AppProvider>
    );
}