import React, { useEffect, useState, useRef } from 'react'

import { Renderer, Geometry, Texture, Program, Flowmap, Mesh, Vec4, Vec2 } from '../ogl/src/index.mjs'


const CanvasItemMobile = ({ imageSrc, id, ...props }) => {
    const canvasRef = useRef(null)



    // State for the unodified wrapper Object div with id from parent component
    // const [originalElement, setOriginalElement] = useState(null)

    // store the original element on mount and update if id of the element changes
    /*
    useEffect(() => {
        setOriginalElement(document.getElementById(id).cloneNode(true))
    }, [id])
    */


    useEffect(() => {
        if (!canvasRef?.current) {
            return
        }




        // Check window size 
        var imgSize = [document.documentElement.clientWidth * 2, document.documentElement.clientHeight * 2];

        let a1, a2;
        var imageAspect = imgSize[1] / imgSize[0];


        if (document.documentElement.clientHeight / document.documentElement.clientWidth < imageAspect) {
            a1 = 1;
            a2 = document.documentElement.clientHeight / document.documentElement.clientWidth / imageAspect;
        } else {
            a1 = (document.documentElement.clientWidth / document.documentElement.clientHeight) * imageAspect;
            a2 = 1;
        }

        const vertex = `
                        attribute vec2 uv;
                        attribute vec2 position;
                        varying vec2 vUv;
                        void main() {
                                vUv = uv;
                                gl_Position = vec4(position, 0, 1);
                        }
                `;
        const fragment = `
                        precision highp float;
                        precision highp int;
                        uniform sampler2D tWater;
                        uniform sampler2D tFlow;
                        uniform float uTime;
                        uniform float uSize;
                        varying vec2 vUv;
                        uniform vec4 res;
                        void main() {
                                // R and G values are velocity in the x and y direction
                                // B value is the velocity length
                                vec3 flow = texture2D(tFlow, vUv).rgb;
                                vec2 uv = .5 * gl_FragCoord.xy / res.xy ;
                                vec2 myUV = (uv - vec2(0.2))*res.zw + vec2(0.2);
                                myUV -= flow.xy * (0.15 * 0.1);
                                vec3 tex = texture2D(tWater, myUV).rgb;
                                gl_FragColor = vec4(tex.r, tex.g, tex.b, 1.0);
                        }
                `;

        const renderer = new Renderer({ dpr: 2, canvas: canvasRef.current });
        const gl = renderer.gl;
        /*
const gatsby = originalElement.cloneNode(true);
console.log(id);
const currentGatsbyInDom = document.getElementById(id);
console.log(currentGatsbyInDom)
currentGatsbyInDom.replaceWith(gatsby);


var element = gatsby.appendChild(gl.canvas);
*/

        //gl.canvas.setAttribute("id", "canvas" + id);
        // setOriginalElement(gl.canvas)

        // Variable inputs to control flowmap
        let aspect = 1;
        const mouse = new Vec2(-3);
        const velocity = new Vec2();

        const flowmap = new Flowmap(gl, { falloff: 0.2, dissipation: 0.9, size: 200, alpha: 1 });
        // Triangle that includes -1 to 1 range for 'position', and 0 to 1 range for 'uv'.
        const geometry = new Geometry(gl, {
            position: {
                size: 2,
                data: new Float32Array([-1, -1, 3, -1, -1, 3])
            },
            uv: { size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2]) }
        });
        const texture = new Texture(gl, {
            minFilter: gl.LINEAR,
            magFilter: gl.LINEAR
        });

        const canvas = document.createElement('canvas');
        canvas.width = document.documentElement.clientWidth * 2;
        canvas.height = document.documentElement.clientHeight * 2;
        const ctx = canvas.getContext('2d');


        const image = new Image();
        image.src = imageSrc;
        image.crossOrigin = "Anonymous";
        image.addEventListener('load', () => {
            scaleToFill(image, ctx);
            texture.image = canvas;
            const can = canvasRef?.current
            if (can) {
                can.classList.remove('no-opacity')
            }
        })



        const program = new Program(gl, {
            vertex,
            fragment,
            uniforms: {
                uTime: { value: 0 },
                tWater: { value: texture },
                res: {
                    value: new Vec4(document.documentElement.clientWidth, document.documentElement.clientHeight, a1, a2)
                },
                img: { value: new Vec2(imgSize[0], imgSize[1]) },
                // Note that the uniform is applied without using an object and value property
                // This is because the class alternates this texture between two render targets
                // and updates the value property after each render.
                tFlow: flowmap.uniform
            }
        });
        const mesh = new Mesh(gl, { geometry, program });

        mesh.program.uniforms.res.value = new Vec4(
            document.documentElement.clientWidth,
            document.documentElement.clientHeight,
            a1,
            a2
        );

        renderer.setSize(document.documentElement.clientWidth, document.documentElement.clientHeight);
        aspect = document.documentElement.clientWidth / document.documentElement.clientHeight;

        // Create handlers to get mouse position and velocity
        const isTouchCapable = "ontouchstart" in window;

        if (document.documentElement.clientWidth < 769) {
            var item = document.querySelector('#scrollWrapper');
        } else {
            var item = window;
        }

        if (isTouchCapable) {
            item.addEventListener("touchstart", updateMouse, false);
            item.addEventListener("touchmove", updateMouse, { passive: false });
        } else {
            item.addEventListener("mousemove", updateMouse, false);
        }

        let lastTime;
        const lastMouse = new Vec2();
        function updateMouse(e) {
            e.preventDefault();
            if (e.changedTouches && e.changedTouches.length) {
                e.x = e.changedTouches[0].pageX;
                e.y = e.changedTouches[0].pageY;
            }
            if (e.x === undefined) {
                e.x = e.pageX;
                e.y = e.pageY;
            }
            // Get mouse value in 0 to 1 range, with y flipped
            mouse.set(e.x / gl.renderer.width, 1.0 - e.y / gl.renderer.height);
            // Calculate velocity
            if (!lastTime) {
                // First frame
                lastTime = performance.now();
                lastMouse.set(e.x, e.y);
            }

            const deltaX = e.x - lastMouse.x;
            const deltaY = e.y - lastMouse.y;

            lastMouse.set(e.x, e.y);

            let time = performance.now();

            // Avoid dividing by 0
            let delta = Math.max(8, time - lastTime);
            lastTime = time;
            velocity.x = deltaX / delta;
            velocity.y = deltaY / delta;
            // Flag update to prevent hanging velocity values when not moving
            velocity.needsUpdate = true;
        }
        requestAnimationFrame(update);
        function update(t) {
            requestAnimationFrame(update);
            // Reset velocity when mouse not moving
            if (!velocity.needsUpdate) {
                mouse.set(-1);
                velocity.set(0);
            }
            velocity.needsUpdate = false;
            // Update flowmap inputs
            flowmap.aspect = aspect;
            flowmap.mouse.copy(mouse);
            // Ease velocity input, slower when fading out
            flowmap.velocity.lerp(velocity, velocity.len ? 0.5 : 0.1);
            flowmap.update();
            program.uniforms.uTime.value = t * 0.01;
            renderer.render({ scene: mesh });

        }

        function scaleToFill(img, ctx) {
            // get the scale
            const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
            // get the top left position of the image
            const x = (canvas.width / 2) - (img.width / 2) * scale;
            const y = (canvas.height / 2) - (img.height / 2) * scale;
            ctx.drawImage(img, x, y, Math.floor(img.width * scale), Math.floor(img.height * scale));
        }
    }, [id, imageSrc]);




    return <div  {...props} ><canvas className="no-opacity" moz-opaque ref={canvasRef} id={`canvas-mobile-${id}`}></canvas></div >
}

export default CanvasItemMobile