import React from 'react'
import Landing from './page/landing'
import Impressum from './page/impressum'
import './page.css'



export default function Page({ ...props }) {
    const pageContext = props?.children?.props?.pageContext

    return (
        <>
            {pageContext?.page?.title === 'Startseite' ?
                <Landing pageContext={pageContext} />
                :
                pageContext?.slug === 'impressum' ?
                    <Impressum pageContext={pageContext} />
                    : <></>
            }
        </>
    )
}
