import React, { useState } from "react";

export const AppContext = React.createContext([{}, () => { }]);

export const AppProvider = (props) => {


    const [slide, setSlide] = useState(null);


    return (
        <AppContext.Provider value={{ slide, setSlide }}>
            {props.children}
        </AppContext.Provider>
    );
};