import React, { useState, useEffect } from 'react';
import CanvasItem from "../canvasItem/canvasItem"
import CanvasItemMobile from "../canvasItemMobile/canvasItemMobile"
import Layout from "../layout"
import Page from "../../templates/page"
import Video from "../video/video"
import Colors from "../colors/colors"
import smoothscroll from 'smoothscroll-polyfill'
import useBreakpoint from 'use-breakpoint'
import { debounce } from 'lodash'


const BREAKPOINTS = { mobile: 0, tablet: 769, desktop: 1280 }

// kick off the polyfill!
smoothscroll.polyfill();

const Canvas = ({ ...props }) => {

  const pageContext = props?.children?.props?.pageContext?.page
  const [sliding, setSliding] = useState(false);
  const [slide, setSlide] = useState(0);
  const [slided, setSlided] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'tablet');

  const [isResized, setIsResized] = useState(0);


  useEffect(() => {

    function handleResize() {
      setIsResized(isResized + 1)
      setSlided(true)
      // Set window width/height to state
      const scrollContainer = document.querySelector('#scrollWrapper')
      if (slide != 0) {
        scrollContainer.scrollTo({
          left: document.documentElement.clientWidth
        })

        setSlided(true)

      } else {
        scrollContainer.scrollTo({
          left: 0
        })
        setSlided(true)
      }
    }
    handleResize()

    // Add event listener
    window.addEventListener("resize", debounce(handleResize, 1000));
    // Call handler right away so state gets updated with initial window size
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);

  }, [slide])

  useEffect(() => {
    const scrollContainer = document.querySelector('#scrollWrapper')
    if (slide != 0) {
      scrollContainer.scrollTo({
        left: document.documentElement.clientWidth
      })

      setSlided(false)

    } else {
      scrollContainer.scrollTo({
        left: 0
      })
      setSlided(false)
    }

  }, [slided])




  async function scrollToPosition(container, position) {
    position = Math.round(position);

    let resolveFn;
    let scrollListener;
    let timeoutId;

    const promise = new Promise(resolve => {
      resolveFn = resolve;
    });
    const finished = () => {
      container.removeEventListener('scroll', scrollListener);
      resolveFn();
    };

    scrollListener = () => {
      clearTimeout(timeoutId);

      // scroll is finished when either the position has been reached, or 100ms have elapsed since the last scroll event
      if (container.scrollLeft === position) {
        finished();
      }
    };

    if (container != null) {
      container.addEventListener('scroll', scrollListener);
      container.scrollTo({
        top: 0,
        left: position,
        behavior: 'smooth',
      });
    }

    return promise;
  }




  function nextSlide() {
    setIsClicked(true)
    if (pageContext?.projekteRepeater?.projects.length - 1 > activeSlide && sliding === false) {
      setSliding(true)
      setActiveSlide(activeSlide + 1)
      const scrollContainer = document.querySelector('#scrollWrapper');
      // desired y coords for scroll
      const yDesiredScroll = scrollContainer.scrollLeft + document.documentElement.clientWidth
      scrollToPosition(scrollContainer, yDesiredScroll).then(() => {
        // do something here
        setSlide(slide + 1)
        setSliding(false)
      })
    }
  }

  function prevSlide() {
    if (activeSlide !== 0 && sliding === false) {
      setSliding(true)
      setActiveSlide(activeSlide - 1)
      const scrollContainer = document.querySelector('#scrollWrapper');
      // desired y coords for scroll


      const yDesiredScroll = 0
      scrollToPosition(scrollContainer, yDesiredScroll).then(() => {
        // do something here
        setSlide(slide - 1)
        setSliding(false)
      })
    }
  }










  return (
    <>


      <Layout {...props}>
        <Page {...props} />
        <div id="prev" className={`prev ${pageContext?.projekteRepeater.projects[activeSlide]?.color == '#000000' ? 'black-prev' : ''}`} onClick={prevSlide}></div>
        <div id="next" className={`next ${pageContext?.projekteRepeater.projects[activeSlide]?.color == '#000000' ? 'black-next' : ''}`} onClick={nextSlide}>{!isClicked ? document.documentElement.clientWidth <= 769 ? pageContext?.projekteRepeater.projects[activeSlide]?.color == '#000000' ? <svg id="e6nK9ZRjuqG1" width="110" height="110" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 640 480" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><g id="e6nK9ZRjuqG2_ts" transform="translate(320,240) scale(1,1)"><ellipse id="e6nK9ZRjuqG2" rx="50" ry="50" transform="translate(1.80851,1.80851)" fill="rgb(0,0,0)" stroke="none" strokeWidth="21" /></g></svg> : <svg id="e6nK9ZRjuqG1" width="110" height="110" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 640 480" shapeRendering="geometricPrecision" textRendering="geometricPrecision"><g id="e6nK9ZRjuqG2_ts" transform="translate(320,240) scale(1,1)"><ellipse id="e6nK9ZRjuqG2" rx="50" ry="50" transform="translate(1.80851,1.80851)" fill="rgb(255,255,255)" stroke="none" strokeWidth="21" /></g></svg> : <></> : <></>}</div>
        {
          pageContext?.projekteRepeater.projects[activeSlide]?.image?.sourceUrl !== undefined ?
            document.documentElement.clientWidth >= 769 ?

              <img className="background-image" style={{ backgroundImage: `url(${pageContext?.projekteRepeater.projects[activeSlide]?.image?.sourceUrl})` }} />
              : <img className="background-image" style={{ backgroundImage: `url(${pageContext?.projekteRepeater.projects[activeSlide]?.imageMobile?.sourceUrl})` }} />
            : <></>
        }

        <div id="scrollWrapper" className="scroll-wrapper">
          {
            pageContext?.projekteRepeater.projects.map((project, i) => {
              return (
                <div id={`id-${i}`} className="height100" key={i} >
                  {slide === i || slide - 1 === i || slide + 1 === i ?
                    <div className="canvas-wrapper" >
                      {breakpoint !== 'mobile' ? (
                        <div key={`${i}-${isResized}`} id={`canvas-id-${i}`} className="project" >
                          {project?.effekt != null ?
                            <div className="image-slider">
                              <CanvasItem imageSrc={project?.image?.sourceUrl} id={`canvas-id-${i}`} />
                            </div>
                            : project?.video?.mediaItemUrl != null ?
                              <Video classOfVideo="video-slider" videoTitle={project?.video?.title} videoSrcURL={project?.video?.mediaItemUrl} />
                              : <div className="image-slider" style={{ backgroundImage: `url(${project?.image?.sourceUrl})` }} />
                          }
                          {slide === i ?
                            <Colors color={project?.color} /> : <></>
                          }
                        </div>
                      ) : (
                        <div id={`canvas-mobile-id-${i}`} className="project-mobile">

                          {project?.effekt != null ?
                            <div className="image-slider">
                              <CanvasItemMobile imageSrc={project?.imageMobile?.sourceUrl} id={`canvas-mobile-id-${i}`} />
                            </div>
                            : project?.video?.mediaItemUrl != null ?
                              <Video classOfVideo="video-slider" videoTitle={project?.videoMobile?.title} videoSrcURL={project?.videoMobile?.mediaItemUrl} />
                              : <div className="image-slider" style={{ backgroundImage: `url(${project?.imageMobile?.sourceUrl})` }} />
                          }
                          {slide === i ?
                            <Colors color={project?.color} /> : <></>
                          }
                        </div>
                      )
                      }
                    </div>
                    : <></>
                  }
                </div>
              )
            })
          }
        </div>
      </Layout>
    </>
  )
}

export default Canvas
