
import PropTypes from "prop-types"
import React from 'react'

const Header = ({ siteTitle }) => {


  return (
    <header className="header">
      <h1 style={{ margin: 0 }}>
        <a

          className="logo"
          href="/"
        >
          {siteTitle}
        </a>

      </h1>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
