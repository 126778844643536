import React from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

import './landing.css'
import './impressum.css'


const Impressum = ({ pageContext }) => {

    const settings = pageContext?.themeFooterSettings;

    return (
        <>
            <Helmet>

            </Helmet>
            <div className="mobile-bar-active" >
                <Link to="/" className="menu-btn black-btn" >close</Link>
            </div>
            <div className="caption">design & art direction</div>
            <style>{"\
                    canvas{\
                    display: none;\
                    }\
                "}
            </style>

            <div className="content imprint-content">
                <Container fluid className="custom-margin">
                    <Row className="justify-content-md-left">
                        <Col xs={12} md={6} className="left-col" >
                            <div dangerouslySetInnerHTML={{ __html: pageContext?.content }} />
                        </Col>
                        <Col xs={12} md={12}><a href={pageContext?.agbLinkField?.agbLink?.url} target={pageContext?.agbLinkField?.agbLink?.target} className="content-menu" dangerouslySetInnerHTML={{ __html: pageContext?.agbLinkField?.agbLink?.title }} /></Col>
                    </Row>
                </Container>
                <div className="left-contact mobile-hide">
                    <h3>{settings?.kontakt?.kontaktHeadline}</h3>
                    <div dangerouslySetInnerHTML={{ __html: settings?.kontakt?.kontakt }} />
                </div>

            </div>
        </>
    )
}

export default Impressum



