import React from "react"
import SEO from "../../components/seo"
import { Container, Row, Col } from 'react-bootstrap'
import useFade from "../../hooks/useFade"
import { Link } from 'gatsby'

import './landing.css'



const Landing = ({ pageContext }) => {

  const [isVisible, setVisible, fadeProps] = useFade();
  const page = pageContext.page
  const settings = pageContext?.themeFooterSettings;



  return (
    <>
      {console.log(pageContext)}
      <SEO seo={pageContext?.page?.seo}></SEO>
      {isVisible ?
        <div className="mobile-bar-active">
          <button onClick={() => setVisible(!isVisible)} className={`menu-btn ${isVisible ? 'black-btn' : ''}`} >close</button>
        </div>
        : <button onClick={() => setVisible(!isVisible)} className={`menu-btn ${isVisible ? 'black-btn' : ''}`} >info</button>
      }
      <div className="caption">design & art direction</div>
      {isVisible &&
        <>
          <div {...fadeProps} className="content">
            <Container fluid className="custom-margin">
              <Row className="justify-content-md-center mobile-padding-bottom">
                <Col xs={12} md={6} className="left-col" >
                  <div dangerouslySetInnerHTML={{ __html: page?.content }} />
                  <div dangerouslySetInnerHTML={{ __html: page?.landing?.textLeft }} />
                </Col>
                <Col xs={12} md={2}></Col>
                <div className="left-contact desktop-hide">
                  <h3>{settings?.kontakt?.kontaktHeadline}</h3>
                  <div dangerouslySetInnerHTML={{ __html: settings?.kontakt?.kontakt }} />
                </div>
                <Col xs={12} md={2} className="not-scrollable mobile-hide">
                  <div className="scrollable bulletArrows" dangerouslySetInnerHTML={{ __html: page?.landing?.textMiddle }} />
                </Col>
                <Col className="desktop-hide" xs={6}><div className="bulletArrows" dangerouslySetInnerHTML={{ __html: page?.landing?.mobileTextLeftCol }} /></Col>
                <Col className="desktop-hide" xs={6}><div className="bulletArrows" dangerouslySetInnerHTML={{ __html: page?.landing?.mobileTextRightCol }} /></Col>

                <Col xs={12} md={2}><Link to="impressum" className="content-menu">impressum</Link></Col>

              </Row>
            </Container>

            <div className="left-contact mobile-hide">
              <h3>{settings?.kontakt?.kontaktHeadline}</h3>
              <div dangerouslySetInnerHTML={{ __html: settings?.kontakt?.kontakt }} />
            </div>
          </div>

        </>
      }
    </>
  )
}

export default Landing



