/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"



function SEO({ seo }) {
  const { pathname } = useLocation()

  const siteUrl = "https://sebastianhofer.com"

  const url = `${siteUrl}${pathname}`



  return (
    <Helmet title={seo?.title} htmlAttributes={{
      lang: 'de',
    }}>
      <meta name="description" content={seo?.metaDesc} />
      <meta name="image" content={seo?.image} />
      <meta property="og:url" content={url} />
      {seo?.opengraphTitle && <meta property="og:title" content={seo?.opengraphTitle} />}
      {seo?.opengraphDescription && (
        <meta property="og:description" content={seo?.opengraphDescription} />
      )}
      {seo?.opengraphImage?.sourceUrl && <meta property="og:image" content={seo?.opengraphImage?.sourceUrl} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo?.title && <meta name="twitter:title" content={seo?.title} />}
      {seo?.twitterDescription && (
        <meta name="twitter:description" content={seo?.twitterDescription} />
      )}
      {seo?.twitterImage?.sourceUrl && <meta name="twitter:image" content={seo?.twitterImage?.sourceUrl} />}
    </Helmet>
  )
}




export default SEO
