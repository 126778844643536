import React from 'react'



const Colors = ({ color }) => {



  return (
    <>
      <style>{"\
        .logo{\
          color: "}{color}{";\
        }\
      "}</style>
      <style>{"\
        .menu-btn{\
          color: "}{color}{";\
        }\
      "}</style>
      <style>{"\
        .caption{\
          color: "}{color}{";\
        }\
      "}</style>
      <style>{"\
        .logo:hover{\
          color: "}{color}{";\
        }\
      "}</style>
    </>
  )
}

export default Colors;